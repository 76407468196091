import React from 'react'
import { graphql } from 'gatsby'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import TextContainer from '../components/TextContainer'
import PageTitle from '../components/PageTitle'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'

const Kontakt = ({ data }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const { body, title, subtitle, headerImage } = data.contentfulPage

  return (
    <Layout>

      <SEO postNode={postNode} pagePath="contact" customTitle />

      <PageTitle
        background={headerImage}
      />

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>
        <PageBody body={body} />
      </Container>

    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "error" }) {
      id
      title
      subtitle
      headerImage {
        id
        fluid(quality: 100, maxWidth: 1920, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`

export default Kontakt
